<!--
 * @Author: DY
 * @Date: 2020-11-04 10:15:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-01 18:22:00
 * @Describe:
-->
<template>
    <div class="voidDeliveryNote">
        <div class="list">
            <h4>选择发货单：</h4>
            <div class="formBox">
                <el-input placeholder="请选择发货单" v-model="invoice">
                    <el-button slot="append" @click="selectShipmentOrder">
                        选择
                    </el-button>
                </el-input>
            </div>
        </div>
        <div class="list">
            <h4>原因：</h4>
            <div class="formBox">
                <el-checkbox-group v-model="reason">
                    <el-checkbox label="设备故障"></el-checkbox>
                    <el-checkbox label="断网"></el-checkbox>
                    <el-checkbox label="断电"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="list">
            <h4>备注：</h4>
            <div class="formBox">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 5, maxRows: 10}"
                    placeholder="请输入内容"
                    v-model="remarks"
                >
                </el-input>
            </div>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>
<script>
import store from '@/store';

export default {
    // 发货单作废
    name: 'void-delivery-note',
    components: {},
    props: {},
    data() {
        return {
            // 发货单号
            invoice: '',
            // 原因
            reason: [],
            // 备注
            remarks: '',
        };
    },
    watch: {},
    activated() {},
    created() {},
    mounted() {},
    methods: {
        // 关闭出层
        cancel() {
            this.$parent.hide();
        },
        // 选择发货单
        selectShipmentOrder() {
            this.$toast({
                title: true,
                text: '选择发货单',
                type: 'eject',
                width: '10rem',
                height: '80%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'fhdzf_xz',
                    },
                    sureCallback: this.confirmSelection,
                },
            });
        },
        // 选择发货单回调
        confirmSelection(data) {
            this.invoice = data;
        },
        // 确认添加
        confirm() {
            if (this.invoice === '') {
                this.$message({
                    message: '请选择发货单',
                    type: 'warning',
                });
                return;
            }
            let newReason = '';
            if (this.reason.length > 0) {
                this.reason.forEach((item, index) => {
                    if (index < this.reason.length - 1) {
                        newReason += item + ',';
                    } else {
                        newReason += item;
                    }
                });
                if (this.remarks !== '') {
                    newReason = newReason + ',' + this.remarks;
                }
                this.$axios
                    .post('/interfaceApi/production/productpcb/invalid', {
                        pcb_number: this.invoice,
                        reason: newReason,
                    })
                    .then(() => {
                        this.$message({
                            message: '添加成功！',
                            type: 'success',
                        });
                        this.$parent.hide();
                        store.state.currentOpenList.forEach(item => {
                            item.tableObj.updateTable();
                        });
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message({
                    message: '请先选择原因',
                    type: 'warning',
                });
            }
        },
    },
    filter: {},
    computed: {},
};
</script>

<style lang="stylus" scoped>
.voidDeliveryNote
    width 100%
    height 100%
    padding 0.2rem
    position relative
    .list
        overflow hidden
        display flex
        margin-bottom 0.2rem
        line-height 0.36rem
        h4
            flex 1
            font-size 0.16rem
            text-align right
            width 1.5rem
        .formBox
            flex 7
    footer
        width 100%
        position absolute
        bottom 0.2rem
        left 0
        text-align center
        .el-button
            width 1.5rem
            padding 0
            font-size 0.16rem
            line-height 0.4rem
            margin 0 0.2rem
            height 0.4rem
</style>
